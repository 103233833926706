import {
  WorkflowReportPayloadEntry,
  WorkflowReportTypes,
  WorkflowsReportDataRow,
} from '@/app/components/WorkflowsReport/ReportTypes';
import {
  mapIntPositionClosedWorkflowsForUI,
  mapIntPositionOpenWorkflowsForUI,
} from '@/app/components/WorkflowsReport/ReportTypes/InternalPosition';
import {
  mapEmpDetailsClosedWorkflowsForUI,
  mapEmpDetailsOpenWorkflowsForUI,
} from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails';
import { mapClosedWorkflowsForUI } from '@/app/components/WorkflowsReport/ReportTypes/Rewarding/dataMapping';

export const getGridData = (
  reportType: WorkflowReportTypes,
  data: WorkflowReportPayloadEntry[],
): WorkflowsReportDataRow[] => {
  switch (reportType) {
    case WorkflowReportTypes.EmployeeDetailsClosed:
      return mapEmpDetailsClosedWorkflowsForUI(data);
    case WorkflowReportTypes.EmployeeDetailsOpen:
      return mapEmpDetailsOpenWorkflowsForUI(data);
    case WorkflowReportTypes.InternalPositionClosed:
      return mapIntPositionClosedWorkflowsForUI(data);
    case WorkflowReportTypes.InternalPositionOpen:
      return mapIntPositionOpenWorkflowsForUI(data);
    case WorkflowReportTypes.RewardingClosed:
      return mapClosedWorkflowsForUI(data);
    case WorkflowReportTypes.RewardingOpen:
      return [];
    default:
      return [];
  }
};
