import translate from '@/app/utils/translate';
import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import {
  AvatarCell,
  FirstNameCell,
  LastNameCell,
  EmpNoCell,
  ScheduledDateCell,
  UpdatedDateCell,
  WorkflowStatusCell,
  StatusApprovedCell,
} from '@/app/components/WorkflowsReport/components/KendoCells';
import { ColumnConfig } from '@/old/utils/helper';

export const commonEmployeeDetailColumnConfig = (): ColumnConfig[] => [
  {
    cell: AvatarCell,
    field: 'img',
    title: translate.t('laPicture'),
    width: 88,
    groupable: false,
    sortable: false,
    show: true,
  },
  {
    cell: (props: React.PropsWithChildren<GridCellProps>) => (
      <FirstNameCell {...props} forceToPeopleModule={true} />
    ),
    field: 'firstName',
    title: translate.t('laFirstName'),
    width: 100,
    show: true,
  },
  {
    cell: (props: React.PropsWithChildren<GridCellProps>) => (
      <LastNameCell {...props} forceToPeopleModule={true} />
    ),
    field: 'lastName',
    title: translate.t('laLastName'),
    width: 125,
    show: true,
  },
  {
    cell: EmpNoCell,
    field: 'empNo',
    show: true,
    title: translate.t('laEmpId'),
    width: 120,
  },
];
export const commonWorkflowDetailColumnConfig = (): ColumnConfig[] => [
  {
    cell: ScheduledDateCell,
    field: 'scheduledDate',
    show: true,
    title: translate.t('laScheduledDate'),
    width: 125,
  },
  {
    cell: UpdatedDateCell,
    field: 'updatedDate',
    show: true,
    title: translate.t('laUpdated'),
    width: 125,
  },
  {
    cell: WorkflowStatusCell,
    field: 'status',
    show: true,
    title: translate.t('laStatus'),
    width: 100,
  },
  {
    cell: StatusApprovedCell,
    field: 'approvedBy',
    show: true,
    title: translate.t('laApproved'),
    width: 175,
  },
];
