import {
  EmploymentDetailsClosedWorkflowReportPayloadEntry,
  EmploymentDetailsOpenWorkflowReportPayloadEntry,
  EmploymentDetailsWorkflowReportEntry,
} from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails';
import {
  RewardingClosedWorkflowReportPayloadEntry,
  RewardingWorkflowReportEntry,
} from '@/app/components/WorkflowsReport/ReportTypes/Rewarding';
import {
  InternalPositionClosedWorkflowReportPayloadEntry,
  InternalPositionOpenWorkflowReportPayloadEntry,
  InternalPositionWorkflowReportEntry,
} from '@/app/components/WorkflowsReport/ReportTypes/InternalPosition';
import { CommonWorkflowReportHistoryData } from '@/app/components/WorkflowsReport/ReportTypes/Common';

export type WorkflowReportPayloadEntry =
  | EmploymentDetailsClosedWorkflowReportPayloadEntry
  | EmploymentDetailsOpenWorkflowReportPayloadEntry
  | InternalPositionClosedWorkflowReportPayloadEntry
  | InternalPositionOpenWorkflowReportPayloadEntry
  | RewardingClosedWorkflowReportPayloadEntry;

export enum WorkflowReportTypes {
  InternalPositionClosed = 'peopleIntPositionsClosedWorkflows',
  InternalPositionOpen = 'peopleIntPositionsOpenWorkflows',
  EmployeeDetailsClosed = 'peopleEmpDetailsClosedWorkflows',
  EmployeeDetailsOpen = 'peopleEmpDetailsOpenWorkflows',
  RewardingClosed = 'rewardingClosedWorkflows',
  RewardingOpen = 'rewardingOpenWorkflows',
}

export type WorkflowsReportDataRow =
  | EmploymentDetailsWorkflowReportEntry
  | InternalPositionWorkflowReportEntry
  | RewardingWorkflowReportEntry;

export type WorkflowsReportDataRowWithHistory = WorkflowsReportDataRow &
  CommonWorkflowReportHistoryData;
