import * as React from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import templateSelectionStyles from './templateSelectionStyles';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Select,
  Tooltip,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import {
  getEmployeeName,
  getLoggedUserId,
  getSelectedEmpId,
} from '@/old/utils/helper';
import { printElements } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import {
  getDefault,
  getLatestArchiveOfEvaluations,
  orderElementsByIndex,
  translateCompletionStatus,
} from '@/app/components/TemplateComponents/helpers';
import EvaluationVisibility from './EditTemplate/EvaluationVisibility/EvaluationVisibility';
import SaveChanges from './EditTemplate/SectionListing/ComponentListing/SaveChanges';
import Loading from '@/app/components/Loading/Loading';
import PrintIcon from '@material-ui/icons/Print';
import { ReducerState } from '@/app/redux/store';
import { connect } from 'react-redux';
import SectionListing from '@/app/components/TemplateComponents/form/FormComponents/SectionListing/SectionListing';
import { ProcessFormTemplate } from '@/app/components/TemplateComponents/types';

type MapStateToProps = {
  processEvaluation: any;
};

type incomingProps = {
  templates: ProcessFormTemplate[];
  archivedProcessEvaluations: [];
  progressStatus: string;
  deadline: Date;
  lastModified: Date;
  approved: string;
  activeTemplate: any;
  updateEvaluation: any;
  openConfirmDialog: any;
  clearEvaluation: any;
  handleEvaluationsSave: any;
  handleTemplateChange: any;
  changeEvaluationApproval: any;
  removeEvaluationApproval: any;
  archiveEvaluation: any;
  setActiveTemplate: any;
  employeeId: number;
  notStartedStatus: boolean;
  changeEvaluationStage: any;
  changeRoleVisibility: any;
};

type PropsType = MapStateToProps &
  incomingProps &
  WithStyles<typeof templateSelectionStyles>;

class TemplateSelection extends React.PureComponent<PropsType> {
  state = {
    editMode: false,
    saveModalOpen: false,
    isLoading: false,
    restricted: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      editMode: false,
      saveModalOpen: false,
      isLoading: false,
      restricted:
        props.activeTemplate.restricted &&
        props.activeTemplate.restricted.length > 0,
    };
  }

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  cancelEvaluation = () => {
    this.props.openConfirmDialog({
      text: translate.t('text_unsaved_changes'),
      onOk: () => {
        this.props.clearEvaluation();
        const defaultTemplate = this.props.templates.find(
          t => t.id === this.props.activeTemplate.id,
        );
        const activeTemplateModified = {
          ...this.props.activeTemplate,
          ...(defaultTemplate && {
            mgrAccess: defaultTemplate.defaultMgrAccess || false,
            hrAccess: defaultTemplate.defaultHrAccess || false,
          }),
        };
        this.props.setActiveTemplate(
          activeTemplateModified,
          this.props.employeeId,
        );
        this.setState({ editMode: !this.state.editMode });
      },
    });
  };

  isEditDisabled = () => {
    const { activeTemplate } = this.props;
    const hasComponents = activeTemplate.sections.find(
      (section: any) => section.components.length,
    );

    if (activeTemplate.restricted && activeTemplate.restricted.length > 0) {
      return true;
    }

    if (!hasComponents) {
      return true;
    }

    if (
      moment(activeTemplate.deadline)
        .endOf('day')
        .isBefore(new Date())
    ) {
      return true;
    }

    if (activeTemplate.archived) {
      return true;
    }

    return false;
  };

  handleSaveModalClose = () => {
    this.setState({ saveModalOpen: false });
  };

  openSaveChangesModal = () => {
    this.setState({ saveModalOpen: true });
  };

  onTemplateChange = (evalId: string) => {
    this.setState({ isLoading: true });
    this.props.handleTemplateChange(evalId).then(() => {
      const { activeTemplate } = this.props;
      if (activeTemplate.restricted && activeTemplate.restricted.length > 0) {
        this.setState({ restricted: true });
      } else {
        this.setState({ restricted: false });
      }
      this.setState({ isLoading: false });
    });
  };

  printEvaluation = (elem: string) => {
    let node = document.getElementById(elem);
    printElements.print([node]);
  };

  onlyHrAdmin() {
    const GLOBAL: any = window;
    const startedEvaluation =
      this.props.activeTemplate.responses !== undefined ? true : false;
    // CASE 1:  NOT HRAdmin and evaluation has started => disable visibility changes
    if (!GLOBAL.iHRAdmin && startedEvaluation) {
      return true;
    }
    return false;
  }

  // This is a temporary additional check - validation should be also assured by backend
  canUserSeeThisEvaluation() {
    const currentUserId = getSelectedEmpId();
    const { activeTemplate } = this.props;
    // No evaluation was started based on this template
    if (
      activeTemplate.currentEvaluationId === undefined ||
      activeTemplate.participants.length === 0
    ) {
      // No evals found so display option to create new evals based on this template
      return true;
    } else {
      if (this.props.activeTemplate.participants.includes(currentUserId)) {
        // Evaluation found and access was granted so display this evaluation as a valid option
        return true;
      } else {
        // Evaluation exists but current user does not have access to this evaluation - don't display
        return false;
      }
    }
  }

  handleChangeApproval(activeTemplate: any) {
    const { changeEvaluationApproval, removeEvaluationApproval } = this.props;
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    if (
      activeTemplate.approvedBy &&
      activeTemplate.approvedBy.includes(currentUserName)
    ) {
      removeEvaluationApproval(
        activeTemplate.currentEvaluationId,
        currentUserName,
      );
    } else {
      changeEvaluationApproval(
        activeTemplate.currentEvaluationId,
        currentUserName,
      );
    }
  }

  render() {
    const {
      classes,
      templates,
      archivedProcessEvaluations,
      activeTemplate,
      archiveEvaluation,
      updateEvaluation,
      clearEvaluation,
      employeeId,
      notStartedStatus,
      handleEvaluationsSave,
      changeEvaluationStage,
      changeRoleVisibility,
      processEvaluation,
    } = this.props;

    const GLOBAL: any = window;
    const { editMode, saveModalOpen, isLoading, restricted } = this.state;
    const editIsDisabled = this.isEditDisabled();
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    const orderedSections = orderElementsByIndex(activeTemplate.sections);
    const latestArchives = getLatestArchiveOfEvaluations(
      archivedProcessEvaluations,
    );
    const activeStage = activeTemplate.setStage
      ? activeTemplate.stages.filter(
          (stage: any) => stage.id === activeTemplate.setStage.id,
        )[0]
      : getDefault(activeTemplate.stages);
    const canCurrentUserSeeEval = this.canUserSeeThisEvaluation();
    const ownPage = getLoggedUserId() === employeeId;
    return (
      <Paper className={classes.root} id="evaluationToPrint">
        <form>
          <div>
            <Select
              native
              onChange={(event: any) =>
                this.onTemplateChange(event.target.value)
              }
              className={`${classes.select} ${
                editMode ? classes.selectDisabled : classes.selectEnabled
              }`}
              inputProps={{
                classes: {
                  icon: editMode ? classes.iconDisabled : classes.icon,
                  root: editMode ? classes.inputDisabled : classes.inputColor,
                },
              }}
              disabled={editMode}
            >
              <optgroup label={translate.t('laActive')}>
                {templates.map(
                  (templateOption: any) =>
                    templateOption.status === 'ACTIVE' &&
                    canCurrentUserSeeEval &&
                    moment(templateOption.deadline)
                      .endOf('day')
                      .isAfter(new Date()) && (
                      <option
                        className={classes.option}
                        key={templateOption.id}
                        value={templateOption.id}
                      >
                        {templateOption.subject}
                      </option>
                    ),
                )}
              </optgroup>
              <optgroup label={translate.t('laLocked')}>
                {templates.map(
                  (templateOption: any) =>
                    templateOption.status === 'ACTIVE' &&
                    canCurrentUserSeeEval &&
                    moment(templateOption.deadline)
                      .endOf('day')
                      .isBefore(new Date()) && (
                      <option
                        className={classes.option}
                        key={templateOption.id}
                        value={templateOption.id}
                      >
                        {templateOption.subject}
                      </option>
                    ),
                )}
              </optgroup>
              <optgroup
                label={`${translate.t('laArchived')} (${translate.t(
                  'laEvaluations',
                )})`}
              >
                {latestArchives.map(
                  (templateOption: any) =>
                    canCurrentUserSeeEval && (
                      <option key={templateOption.id} value={templateOption.id}>
                        {templateOption.templateSubject}
                      </option>
                    ),
                )}
              </optgroup>
            </Select>
            {!editMode && (
              <>
                <IconButton
                  disabled={editIsDisabled}
                  className={classes.iconButton}
                  onClick={() => this.toggleEditMode()}
                >
                  <Tooltip title={translate.t('laEdit')}>
                    <EditIcon color={editIsDisabled ? 'disabled' : 'primary'} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.printEvaluation('evaluationToPrint')}
                >
                  <Tooltip title={translate.t('laPrint')}>
                    <PrintIcon color="primary" />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </div>
          {restricted && (
            <Typography variant="h3" className={classes.naText}>
              {' '}
              N/A
            </Typography>
          )}
          <div style={restricted ? { display: 'none' } : { display: 'block' }}>
            {editMode && (
              <span className={classes.buttonWrapper}>
                <Button
                  color="primary"
                  variant="text"
                  className={classes.button}
                  onClick={() => this.cancelEvaluation()}
                >
                  {translate.t('laCancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  onClick={() => this.openSaveChangesModal()}
                >
                  {translate.t('laSave')}
                </Button>
              </span>
            )}
            <div className={classes.flexWrapper}>
              <TextField
                label={translate.t('laApprovalStatus')}
                inputProps={{ readOnly: true, disabled: true }}
                value={
                  notStartedStatus
                    ? translate.t('label_notStarted')
                    : translateCompletionStatus(activeTemplate.completionStatus)
                }
                className={classes.spacing}
              />
              {activeStage && (
                <TextField
                  label={translate.t('laStage')}
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  value={`${activeStage ? activeStage.label : ''}`}
                  className={`${classes.spacing} ${classes.textField}`}
                />
              )}
              <TextField
                label={translate.t('laDeadline')}
                inputProps={{ readOnly: true, disabled: true }}
                value={activeTemplate.deadline}
                className={classes.spacing}
              />
              <TextField
                label={translate.t('laModifiedTime')}
                inputProps={{ readOnly: true, disabled: true }}
                value={`${activeTemplate.lastEdited}, ${activeTemplate.lastModifiedBy}`}
                className={`${classes.spacing} ${classes.textField}`}
              />
              <TextField
                label={translate.t('laApproved')}
                inputProps={{ readOnly: true, disabled: true }}
                InputLabelProps={{ shrink: true }}
                value={activeTemplate.approvedBy}
                className={`${classes.spacing} ${classes.approved}`}
              />
              <div className={classes.removeApprovalWrapper}>
                {activeTemplate.archived && (
                  <Typography variant="h3">
                    {translate.t('laArchived')}
                  </Typography>
                )}
                {!activeTemplate.archived && !editMode && (
                  <>
                    <Tooltip title={translate.t('laEvaluationTooltipApprove')}>
                      <span>
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="text"
                          onClick={() =>
                            this.handleChangeApproval(activeTemplate)
                          }
                          disabled={
                            activeTemplate.completionStatus === 'IN_PROGRESS' ||
                            !activeTemplate.currentEvaluationId ||
                            this.isEditDisabled()
                          }
                        >
                          {activeTemplate.approvedBy.includes(currentUserName)
                            ? translate.t('laRemoveApproval')
                            : translate.t('laApprove')}
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip title={translate.t('laEvaluationTooltipArchive')}>
                      <span>
                        <Button
                          className={classes.button}
                          color="primary"
                          variant="text"
                          onClick={() => {
                            archiveEvaluation(
                              activeTemplate.currentEvaluationId,
                            );
                          }}
                          disabled={
                            activeTemplate.archived ||
                            activeTemplate.completionStatus === 'IN_PROGRESS' ||
                            !activeTemplate.currentEvaluationId ||
                            this.isEditDisabled()
                          }
                        >
                          {translate.t('laArchive')}
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
            {isLoading && <Loading key="loading" />}
            {!isLoading && (
              <EvaluationVisibility
                key={'eval-visibility'}
                activeTemplate={activeTemplate}
                editMode={editMode}
                changeRoleVisibility={changeRoleVisibility}
                employeeId={employeeId}
              />
            )}
            {!isLoading && orderedSections && orderedSections.length > 0
              ? orderedSections.map((section: any) => (
                  <SectionListing
                    key={section.id}
                    template={activeTemplate}
                    section={section}
                    list={[
                      {
                        key: section.id,
                        label: section.name,
                        componentsList: section.components || [],
                      },
                    ]}
                    /* Section is editable by
                case 1: everyone,
                case 2: everyone but employees(unless HR/Manager are looking at their own profile) ,
                case 3: only Employees and HR
              */
                    editMode={
                      (editMode &&
                        !section.onlyEmpAccess &&
                        section.empAccess) ||
                      (editMode &&
                        !section.onlyEmpAccess &&
                        !GLOBAL.iEmployee &&
                        getLoggedUserId() !== employeeId) ||
                      (editMode &&
                        section.onlyEmpAccess &&
                        (GLOBAL.iEmployee || GLOBAL.iHR || ownPage))
                    }
                    updateEvaluation={updateEvaluation}
                    employeeId={employeeId}
                    responses={processEvaluation.responses}
                  />
                ))
              : !isLoading && (
                  <Typography className={classes.noSections}>
                    {translate.t('no_sections')}
                  </Typography>
                )}
            <Modal
              open={saveModalOpen}
              onClose={() => this.handleSaveModalClose()}
            >
              <SaveChanges
                employeeId={employeeId}
                completionStatus={activeTemplate.completionStatus}
                activeTemplate={activeTemplate}
                handleModalClose={this.handleSaveModalClose}
                handleEvaluationsSave={handleEvaluationsSave}
                clearEvaluation={clearEvaluation}
                toggleEditMode={this.toggleEditMode}
                archiveEvaluation={archiveEvaluation}
                changeEvaluationStage={changeEvaluationStage}
                responses={processEvaluation.responses}
              />
            </Modal>
          </div>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { processEvaluation } = state.processEvaluations;

  return {
    processEvaluation,
  };
};

const enhance = compose<any, any>(
  connect(mapStateToProps),
  withStyles(templateSelectionStyles),
);

export default enhance(TemplateSelection);
