import moment from 'moment/moment';
import {
  commonWorkflowsReportDataMapping,
  commonWorkflowsReportUnitDataMapping,
  mapProposalValueChanges,
  OpenWorkflowEntryPayload,
  WorkflowReportEmpDetail,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import {
  EmploymentDetailsClosedWorkflowReportPayloadEntry,
  EmploymentDetailsOpenWorkflowReportPayloadEntry,
  EmploymentDetailsProposalPayload,
  EmploymentDetailsWorkflowReportEntry,
} from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails/types';

const mapProposalPayloadData = (proposal: EmploymentDetailsProposalPayload) => {
  return {
    empStatus: proposal.fEmploymentStatusFK,
    pg1: proposal.fPersonnelGroup1FK,
    pg2: proposal.fPersonnelGroup2FK,
    pg3: proposal.fPersonnelGroup3FK,
    pg4: proposal.fPersonnelGroup4FK,
    pg5: proposal.fPersonnelGroup5FK,
    startDate: moment(proposal.fStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    endDate: !!proposal.fEndDate
      ? moment(proposal.fEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
      : '',
    info: proposal.fInfo,
    jobGrade: proposal.fJobGradeFK,
    taxNumber: proposal.fTaxNumber,
    vacBalCalcUseSixDays: proposal.fVacBalCalcUseSixDays,
    calcHoursBalance: proposal.fCalcHoursBalance,
  };
};

const mapPayloadDataToReportEntry = (
  details: WorkflowReportEmpDetail,
  workflowEntry: OpenWorkflowEntryPayload,
  proposal: EmploymentDetailsProposalPayload,
  reference: EmploymentDetailsProposalPayload,
): EmploymentDetailsWorkflowReportEntry => {
  const {proposal: mappedProposal, changes} = mapProposalValueChanges(
    mapProposalPayloadData(proposal),
    mapProposalPayloadData(reference),
  );

  return {
    ...commonWorkflowsReportDataMapping(details, workflowEntry),
    ...commonWorkflowsReportUnitDataMapping(details),
    ...mappedProposal,
    referenceValues: changes,
  };
};

export const mapEmpDetailsClosedWorkflowsForUI = (
  data: EmploymentDetailsClosedWorkflowReportPayloadEntry[],
): EmploymentDetailsWorkflowReportEntry[] => {
  return data
    .map(reportEmployee => {
      const {
        fEmpDetails: details,
        fEmpClosedWorkflow: workflows,
      } = reportEmployee;
      return workflows.map(
        (workflow): EmploymentDetailsWorkflowReportEntry => {
          const {
            fClosedWorkflowEntry: workflowEntry,
            fEmploymentDetailsProposal: { translate: proposal },
            fCurrentDetails: { translate: reference },
          } = workflow;

          return mapPayloadDataToReportEntry(
            details,
            workflowEntry,
            proposal,
            reference,
          );
        },
      );
    })
    .flat();
};

export const mapEmpDetailsOpenWorkflowsForUI = (
  data: EmploymentDetailsOpenWorkflowReportPayloadEntry[],
): EmploymentDetailsWorkflowReportEntry[] => {
  return data
    .map(reportEmployee => {
      const {
        fEmpDetails: details,
        fEmpOpenWorkflow: workflows,
      } = reportEmployee;

      return workflows.map(
        (workflow): EmploymentDetailsWorkflowReportEntry => {
          const {
            fOpenWorkflowEntry: workflowEntry,
            fEmploymentDetailsProposal: { translate: proposal },
            fCurrentDetails: { translate: reference },
          } = workflow;

          return mapPayloadDataToReportEntry(
            details,
            workflowEntry,
            proposal,
            reference,
          );
        },
      );
    })
    .flat();
};
