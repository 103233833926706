import moment from 'moment/moment';
import { getEmployeeName } from '@/old/utils/helper';
import {
  CommonWorkflowReportData,
  CommonWorkflowReportUnitData,
  WorkflowReportEmpDetail,
} from '@/app/components/WorkflowsReport/ReportTypes/Common/types';

export const commonWorkflowsReportDataMapping = (
  empDetails: WorkflowReportEmpDetail,
  workflowEntry: any,
): CommonWorkflowReportData => {
  return {
    empId: empDetails.fEmpId,
    firstName: empDetails.fEmpFirstName,
    lastName: empDetails.fEmpLastName,
    empNo: empDetails.fEmpPersonNumber,
    ext: !empDetails.fEmpHasAccount,

    type: workflowEntry.fOpenWorkflowType,
    workflowId: workflowEntry.fOpenWorkflowUid,
    group: workflowEntry.fOpenWorkflowGroup,
    scheduledDate: moment(
      workflowEntry.fOpenWorkflowScheduledDate,
      'YYYY-MM-DD',
    ).format('DD.MM.YYYY'),
    updatedDate: moment(
      workflowEntry.fOpenWorkflowLastUpdated,
      'YYYY-MM-DD',
    ).format('DD.MM.YYYY'),
    approvedBy: getEmployeeName(workflowEntry.fOpenWorkflowUpdatedByEmpId),
    status: workflowEntry.fOpenWorkflowStatus,
  };
};

export const commonWorkflowsReportUnitDataMapping = (
  empDetails: WorkflowReportEmpDetail,
): CommonWorkflowReportUnitData => {
  return {
    unitId: empDetails.fEmpUnitDetails.fOrgUnitId,
    unit: empDetails.fEmpUnitDetails.fOrgUnitName,
    unitNo: empDetails.fEmpUnitDetails.fOrgUnitNumber,
  };
};

export const mapProposalValueChanges = <T extends object>(
  proposal: T,
  reference: T,
): { proposal: T; changes: Partial<T> } => {
  const changes = Object.keys(reference).reduce((acc, key) => {
    const refValue = reference[key];

    if (proposal[key] !== refValue) {
      return {
        ...acc,
        [key]: refValue,
      };
    }

    return acc;
  }, {});

  return { proposal, changes };
};